import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Header from "../../components/Header"

const bgImage = "/header.jpg"

export default () => (
	<Layout>
		<SEO pageTitle="Brow treatments" />
		<Header pageTitle="Brow treatments" bgImage={bgImage} />
		<section className="intro-text">
			<div className="container">
				<p>Brow treatments intro.</p>
			</div>
		</section>
		<section className="grey-bg">
			<div className="container">
				<h2>HD Brows &ndash; &pound;28</h2>
				<p>HD Brows is both a luxury and bespoke eyebrow treatment that is totally tailored to you - not a brow stencil in sight. It's so much more than a standard eyebrow tint and wax; your HD trained Elite stylist will combine a unique design formula with custom-blended tinting, hair removal and make up application for bespoke brows that suit you. Your stylist will work with you to create your perfect look, whether your wish to tame bushy brows or regrow overplucked eyebrows. Your stylist will be able to help guide you into keeping your brows looking great in between appointments.</p>
				<p>A topical patch test will be required for this treatment if you are a new client to the salon, have had a gap of 6 months or more between tinting, or if you have had any changes in your medical history inbetween appointments, including any new vaccinations.</p>
				<p>This treatment lasts up to 6 weeks and takes up to 45 minutes.</p>
				<p>This treatment can only be carried out if you for 16 years and over. Also please note that if you are pregnant or breastfeeding, you will not be able to have the tinting element of this treatment.</p>
				<Link className="button cta" aria-label="Book HD Brows now" to="/book">Book now</Link>
			</div>
		</section>
		<section>
			<div className="container">
				<h2>HD Express brows &ndash; &pound;15</h2>
				<p>This treatment is perfect if you are short on time, just want a basic tint and thread, or just need a tidy up in between those full HD Brow treatments.Time taken 15 minutes.</p>
				<p>A basic brow shape (without tint) can be carried out from 12 years of age.</p>
				<Link className="button cta" aria-label="Book HD Express brows now" to="/book">Book now</Link>
			</div>
		</section>
		<section className="grey-bg">
			<div className="container">
				<h2>HD browsculpt/lamination &ndash; &pound;39</h2>
				<p>Whether you dream of achieving that full, fluffy eyebrow look, want to create symmetry in uneven brows or tame coarse, wiry unruly hairs, our BrowSculpt brow lamination treatment is for you. This corrective treatment helps you to achieve your desired brow position for up to 6 weeks. Time taken is 1 hour.</p>
				<Link className="button cta" aria-label="Book HD browsculpt/lamination now" to="/book">Book now</Link>
			</div>
		</section>
		<section>
			<div className="container">
				<h2>Microblading &ndash; &pound;280</h2>
				<p>Microblading is a method used to create super realistic hair stroke brows. It involves using a disposable hand-tool with super fine needles to deposit pigment within the upper dermis layer of the skin to create the illusion of brow hair. The treatment is carried out over 2 sessions 8 weeks apart, and annual colour boosts will be required to keep your brows looking fresh.</p>
				<p>This is the perfect treatment if you have suffered hair loss eg, due to medical conditions/treatments, medicines, overplucking, also great if you struggle with applying makeup due to poor sight, or simply love the idea that you wake up and don't have to make up, saving you much needed time in your morning routine.</p>
				<p>Only available for 18 years and over, and a patch test will be required before your treatment. Time taken to do this treatment is upto 2.5 hours per session.</p>
				<Link className="button cta" aria-label="Book microblading now" to="/book">Book now</Link>
			</div>
		</section>
	</Layout>
)